
.contenedor-tarjeta {
    width: 100%;
    height: 98vh;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.tarjeta {
    max-width: 408px !important;
    background-color: #fff;
    height: auto;
    padding: 50px;
    border-radius: 20px;
    color: #37474f;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.tarjeta p {
    margin-top: 40px !important;
    font-size: 25px;
    font-weight: bold;
    max-width: 308px !important;
}


.cargando  {
    --s: 280px; /* control the size */
    display: grid;
    width: var(--s);
    aspect-ratio: 1;
    overflow: hidden;
    padding: calc(var(--s)/28);
    border-radius: 50%;
    position: relative;
    clip-path: circle(49.5%); /* to avoid a few glitch cause by overflow: hidden */
}

.cargando::after {
    content: "";
    position: absolute;
    inset: 0;
    padding: inherit;
    border-radius: inherit;
    background: repeating-conic-gradient(#ffffff 0 50deg,#01579b 0 180deg);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}

.cargando > img {
    grid-area: 1/1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    transform-origin: 50% 120.7%;
}

.cargando > img, .cargando::after {
    animation: m 8s infinite cubic-bezier(.5,-0.2,.5,1.2);
}

.cargando > img:nth-child(2) {animation-delay: -2s}
.cargando > img:nth-child(3) {animation-delay: -4s}
.cargando > img:nth-child(4) {animation-delay: -6s}
  
@keyframes m {
    0%,3%    {transform: rotate(0)}
    22%,27%  {transform: rotate(-90deg)}
    47%,52%  {transform: rotate(-180deg)}
    72%,77%  {transform: rotate(-270deg)}
    98%,100% {transform: rotate(-360deg)}
}
  