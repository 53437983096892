$simutag: #23926c;
$smartpls: #16549e;
$automatizacion: #a9252f;
$naranjod: #f2943b;
$duhovit: #1d1d1d;
$negro: #333;

$gris: #607d8b; // blue-grey

$gris-oscuro: #37474f; //  blue-grey darken-3

$azul: #01579b; // light-blue darken-4

$cyan: #00acc1; // cyan darken-1

$rojo: #c62828; // red darken-3

$amarillo: #fdd835; // yellow darken-1

$naranjo: #ff8a65; // deep-orange lighten-2

$claro: #f1f8e9; // light-green lighten-5

$azul2: #1e67b0;
$azul3: #0f506f; 
$azul4: #145f7a;
$azul5: #c8edff; 
$cobre1: #9e5716;
$cobre2: #6f3d0f;
$cobre3: #b47234;

.cont-tab.ocultar {
    overflow-y: hidden !important;
}

html {
    overflow: hidden !important;
    height: 100%;
}

html, button, select, input {
    box-shadow: none !important;
    font-family: 'Heebo', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
    // overflow-y: hidden !important;
    background-color: #F4F6F5 !important;
    // // background-image: url("./assets/img/fondo.jpg") !important;
    // background-repeat:no-repeat;
    // -webkit-background-size:cover;
    // -moz-background-size:cover;
    // -o-background-size:cover;
    // background-size:cover;
    bottom: 0;
    width: 100%;
}

.contenido {
    margin-left: 70px;
    padding-right: 20px;
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    height: 90vh;
}

.contenedor {
    position: relative;
    box-sizing: border-box !important;
    outline: 10px solid transparent !important;
    padding: 15px;
    // padding-right: 100px !important;
    // padding-left: 100px !important;
    // box-shadow:rgba(0, 0, 0, 0.2) 0px 2px 2px 0px;
    background-color: rgba(255,255,255);
    border: 1px solid #00000018;
    border-radius: 10px;
    // max-height: 90vh;
    margin-bottom: 10px;
    overflow-y: hidden !important;
    width: 100% !important;
}

hr {
    border-color: $azul;
}

/* width */
.contenido::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.contenido::-webkit-scrollbar-track {
    background: #607d8b25;
}

/* Handle */
.contenido::-webkit-scrollbar-thumb {
    background: $gris-oscuro;
}

/* Handle on hover */
.contenido::-webkit-scrollbar-thumb:hover {
    background: $gris-oscuro;
}


.modal {
    border-radius: 20px !important;
    border: none !important;

    h5 {
        margin-top: 0;
        margin-bottom: 15px !important;
        font-size: 25px !important;
        color: $azul !important;
        font-weight: bold;
        text-transform: uppercase;
    }
}


.toast.error {
    background-color: #ef5350 !important;
    color: #fff;
}

#toast-container {
    top: 2% !important;
    right: 2% !important;
}

.scroll::-webkit-scrollbar {
    width: 10px !important;
    height: 10px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
     background: #0000001a !important;
     border-radius: 3px !important;
}
  
/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: $negro !important;
    border-radius: 2px !important;
}
  
/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: $negro !important;
}

.toast {
    border-radius: 10px;
}

.cont-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    overflow: hidden !important;

    p {
        color: $gris-oscuro;
        font-weight: bold;
        font-size: 22px ;
    }

    &.lmodal {
        min-height: 200px !important;
        max-height: 200px !important;
    }
}

.modal.carga {
    width: 20% !important;

    .modal-content p {
        margin: 0 !important;
        width: 100%;
        text-align: center;
        font-weight: bold;
        color: $gris-oscuro;
        font-size: 20px;
    }
}

.error-obt-datos {
    width: 100%;
    text-align: center !important;

    i {
        font-size: 100px !important;
        color: #a9252f;
    }

    p {
        margin-top: 10px !important;
        font-size: 20px !important;
        font-weight: bold;
        color: $gris-oscuro
    }
}