@import '../../app.scss';

/* ==============================================================  FONDO */
body {
    margin: 0;
    padding: 0;
    background-image: none;
    background-color: #F4F6F5 !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.contenedor-autentificacion {
    background-color: #F4F6F5 !important;
}

.fondo-login {
    overflow: hidden;
}

.contenedor-autentificacion {
    height: 100vh;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}


.cont-login {
    transition: 0.3s;
    border-radius: 12px;
    width: 70%;
    display: grid;
    grid-template-columns: 67% 33%;
    border: 1px solid #EBEBEB;
    // box-shadow: 0 5px 5px 0 rgb(0 0 0 / 20%), 0 3px 0px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    // -webkit-box-shadow: 0 5px 5px 0 rgb(0 0 0 / 20%), 0 3px 0px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 20%);

    > .img-autentificacion {
        position: relative;
        text-align: center;
        border-radius: 12px 0  0 12px;
        height: 80vh;
        background-image: url('../../assets/img/fondo_login.jpg');
        background-position: 0px 0px;
        background-repeat: repeat-x;
        animation: animatedBackground 150s linear infinite alternate;
        padding: 30px;
    }
}

// #toast-container {
//     right: auto !important;
//     left:7%;
// }
  
/* ==============================================================  FORMULARIO */
.card {
    background-color: #fff !important;
    border-radius: 0 12px 12px 0;
    box-shadow: none !important;
    margin: 0;
    height: 80vh;
    text-align: center;
    
    padding: 20px;

    > img {
        width: 75% !important;
        margin-top: 20%;
    }

    > h2 {
        margin-top: 15px;
        color: #fff;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 0 !important;
    }

    > form {
        padding: 10px 20px 15px 20px;
    }
}



/* ==============================================================  INPUTS FORM */
.input-field.input-outlined {
    margin-bottom: 0;
    margin-top: 25px !important;

    > input {
        background-color: #f2f2f2 ;
        transition: box-shadow, background-color 0.15s;
        padding-left: 5px !important;
        margin-bottom: 0 !important;
        color: $negro !important;
        border-radius: 0 5px 5px 0 !important;
        border: 2px solid rgba(0,0,0,0);
        box-sizing: border-box !important;
        font-size: 15px !important;
        height: 45px !important;

        &:focus:not([readonly]) {
            border-color: transparent !important;
            background-color: #01589b23;
            box-shadow: none !important;
            border-color: transparent !important;
        }

        &.invalid {
            background-color: #c6282846;
            border-color: transparent !important;
            box-shadow: none !important;
        }

        &.valid {
            border-color: rgba(0,0,0,0);
            box-shadow: none !important;
        }
    }

    > label {
        display: flex !important;
        color: $negro;
        font-size: 15px;
        left: 5px;
        width: auto !important;

        &.active {
            color: $negro !important;
            font-weight: bold;
            top: -5px;
            left: -1px;
        }
    }

    > i {
        top: 0;
        font-size: 20px;
        padding-top: 13px;
        height: 45px;
        color: #fff;
        background-color: $negro;
        border-radius: 5px 0 0 5px !important;
        transition: background-color 0.15s;

        &.active {
            background-color: $azul;
            color: #fff;
        }
    }

    &.clave {
        margin-top: 30px !important;
    }
}

.error-msg {
    color: $rojo;
    font-size: 13px;
    font-weight: bold;
    text-align: right;
    margin-top: 3px;
    margin-bottom: 0 !important;

    > i {
        font-size: 15px;
        vertical-align: middle;
        margin-top: -3px !important;
    }
}

.separador {
    height: 10px;
}

// ANIMACIONES DE FORM
// .input-field.input-outlined.clave, .cont-captcha,
// .recuperar-clave, .btn-ingresar {
//     transition: visibility 0s, opacity 0.5s linear, ;
// }

// .cont-inputs {
//     transition: max-height 3s ease-in !important;
// }

.cont-inputs {
    transition: transform 0.3s ease;
    transform-origin: top;
}

.cont-btn-validar {
    transition: margin-top 0.3s ease;
}

.btn-validar:disabled {
    background-color: $azul !important;
    box-shadow: none !important;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%) !important;
}

.progress {
    margin-top: 15px;
    background-color: rgba(255,255,255,0.5);

    .indeterminate {
        background-color: #fff;
    }
}

/* ==============================================================  */

.cont-captcha {
    display: flex;
    align-items: center;
    justify-content: center;

    .captcha {
        width: 150px;
        background-color: #fff;
        height: 80px;
        border-radius: 12px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}


.btn-ingresar, .btn-validar {
    font-size: 18px;
    font-weight: bold;
    border-radius: 7px;
    width: 100%;
}

.cont-btn-validar {
    padding-right: 20px !important;
    padding-left: 20px !important;

    > button {
        margin-bottom: 5px;
    }
}

.recuperar-clave {
    margin-top: 10px !important;
    color: $claro;
    transition: font-weight 0.2s ease;

    &:hover {
        font-weight: bold;
 
    }
}

button {
    border-radius: 5px !important;
    text-transform: none !important;
    font-size: 15px !important;
}


/* =========================================== EVITAR FONDO BLANCO */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

