@import '../../../../app.scss';

.cont-historial-estados {
    overflow-y: auto;
    max-height: 370px !important;
}

.cont-fecha-descarga {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 10px;
    
    > input {
        font-size: 17px !important;
        text-align: center;
        border: 1px solid $azul !important;
        border-radius: 12px !important;
        padding: 0 !important;
        margin: 0 !important;
        height: 36px !important;
    }

    > .btn-descargar-historial {
        border-radius: 12px;
        font-weight: bold;
        font-size: 17px;
    }
}

.tabla-historial-estados {
    border-collapse: separate;
    thead tr th {
        position: sticky;
        top: 0;
    }

    th, td {
        border: 1px solid #fff;
        border-radius: 0 !important;
    }

    th {
        background-color: #3677b0 !important;
        color: #fff;
        border-radius: 0;
        text-align: center;
        padding: 7px;
        font-size: 18px;
    }

    td {
        padding: 7px;
        background-color: #f2f2f2;
        text-align: center;
        transition: 0.1s;
    }

    tr:hover td {
        background-color: #e1ecf5 !important;
    }
}

.div-no-estados {
    width: 100%;
    height: 35vh;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    p {
        font-size: 25px !important;
        font-weight: bold;
        color: rgba(0,0,0,0.5);
    }
}

.modal.estados {
    overflow: hidden;
    max-height: 80vh !important;

    .clase.tabs {
        margin-top: 10px;
        text-align: left;
    }

    .tab {
        width: auto !important;
    }

    .tab a {
        color: $gris-oscuro !important;
        font-size: 17px !important;
        font-weight: bold;
        transition: 0.8s !important;
        border-radius: 10px 10px 0 0;

        &.active {
            color: $azul !important;
            background-color: #c8edff83 !important;
        }
    }

    .tab a:focus {
        background-color: #c8edff83 !important;
    }

    .indicator {
        background-color: $azul !important;
    }

    > .modal-content {
        text-align: left !important;
        > .tabs {
            background-color: transparent;
            
        }

        .cont-tab.estados {
            background-color: #D0D8E1 !important;
            padding: 15px;
            // border-radius: 0 0 12px 12px;
            overflow: hidden;
        }

        .cont-historial-estados {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}