@import '../../../../app.scss';

.modal.cosecha {
    max-width: 500px !important;
    text-align: center !important;

    h6 {
        color: $gris-oscuro;
        font-weight: bold;
        font-size: 18px;
    }
}

.cont-var-cosecha {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 16px !important;

    label, label.active {
        text-align: left !important;
        color: $gris-oscuro !important;
        font-size: 16px !important;
        font-weight: bold;
    }

    input {
        padding-right: 10px !important;
        border: 1px solid #00000027 !important;
        border-radius: 5px !important;
        height: 32px !important;
        padding-left: 5px !important;
        box-sizing: border-box !important;
        text-align: right;
        color: $gris-oscuro !important;

        &:focus {
            border-color: $azul !important;
        }
    }

    input:disabled {
        border: none !important;
        color: $gris-oscuro !important;
    }

    button:first-child {
        margin-right: 5px !important;
    }
}

