@import '../../../../app.scss';
// .fila-superior.cosecha {
//     display: grid;
//     grid-template-columns: 0.4fr 1.6fr;
// }

.cont-btns.cosecha {
    .btn-exportar {
        margin-right: 5px !important;
    }
}

.tab-cosecha {
    padding-top: 10px;
    display: none;
    overflow-y: auto;
}

.tab-cosecha.activo {
    display: block;
}

.tab-cosecha h5 {
    font-weight: bold;
    color: $azul;
    text-transform: uppercase;
    box-sizing: border-box !important;
}


.tab-informe {
    border: 2px solid #dee4e7;
    border-radius: 0 0 7px 7px;
    padding: 10px;
    display: none;
}

.tab-informe.activo { 
    display: block;
}

.contenido-cosecha {
    overflow-y: auto;
    max-height: 70vh;
    padding-right: 5px;

    h6 {
        color: $azul;
        font-weight: bold;
        font-size: 23px;
    }
}