@import '../../../app.scss';

.pl4 {
    align-items: flex-end;
    display: flex;
	margin: 1.5em;
	width: 6em;
	height: 6em;
    
	.pl4__a, .pl4__b, .pl4__c, .pl4__d {
		animation: bounce4 2s linear infinite;
		transform-origin: 50% 100%;
        width: 1.75em;
		height: 0.75em;
    }

    .pl4__a {
		background: $simutag;
    }

	.pl4__b {
		background: $smartpls;
		animation-delay: 0.1s;
    }

	.pl4__c {
		background: $automatizacion;
		animation-delay: 0.2s;
    }

    .pl4__d {
		background:  $naranjod;
		animation-delay: 0.3s;
    }
}

@keyframes bounce4 {
    from, 20%, 40%, 60%, 80%, to {
		transform: scaleY(1);
		animation-timing-function: ease-out;
    }

	10% {
		transform: scaleY(8);
		animation-timing-function: ease-in;
    }

	30% {
		transform: scaleY(4);
		animation-timing-function: ease-in;
    }

	50% {
		transform: scaleY(2);
		animation-timing-function: ease-in;
    }

	70% {
		transform: scaleY(1.5);
		animation-timing-function: ease-in;
    }
}