@import '../../../../app.scss';

.fila-superior.sim-sx {
    button {
        border-radius: 7px !important;
    }

    button:first-child {
        margin-right: 5px;
    }

    .cont-btns {
        border-radius: 0 !important;
    }
}

.modal.sim-sx {
    text-align: center ;
    overflow-y: hidden !important;
    
    .modal-content {
        overflow-y: hidden !important;
    }

    .cont-loader {
        min-height: 15vh !important;
    }
}

.cont-tabla-sim-sx {
    background-color: #D0D8E1 !important;
    padding: 15px;
    border-radius: 12px;

    .cont-tabla {
        min-height: 20vh !important;
        max-height: 30vh !important;
        overflow-y: auto !important;
    }
}

.cont-tabla.sim-sx {
    border-collapse: separate;

    th, td {
        padding: 5px !important;
        border-radius: 0 !important;
        text-align: center;
        border: 1px solid #fff;
    }

    tr {
        background-color: #fff;
    }

    th {
        color: #fff;
        background-color: $azul;
        font-size: 16px !important;
        position: sticky;
        top: 0;
    }

    td {
        background-color: #f2f2f2;
        color: $gris-oscuro;
        font-size: 15px !important;
        transition: 0.1s;
    }

    tr.impar td {
        background-color: #e9e9e9;
    }

    tr:hover td {
        background-color: #16539e31 !important;
        font-weight: bold;
    }
}
