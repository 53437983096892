@import '../../../../app.scss';

.contenedor-datos-resumen {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;
    padding-left: 5px;
    padding-right: 5px;
}

.cont-resumen-met {
    display: grid;
    grid-template-columns: 1.2fr 1.8fr ;
    column-gap: 10px;
    height: 77vh;
}

.cont-estadistica.oxe {
    border-radius: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.cont-estadistica.met {
    // background-color: #D0D8E1;
    width: 100%;
    display: block !important;
    overflow: hidden !important;
    // height: 500px;
}

.cont-tabla-resumen.oxe {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
}

.cont-tabla.met {
    max-height: 80vh !important;
}

.cont-tablas-resumen.met {
    max-height: 75vh;

    > div {
        display: flex;
        height: 100% !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }

    > div table:first-child {
        margin-right: 5px;
    }

}

.btn-historial-estados {
    border-radius: 12px;
    font-weight: bold;
    font-size: 16px;
    margin-right: 10px;

    > i {
        font-size: 23px;
        margin-right: 5px;
    }

    &:hover {
        background-color: rgb(253, 141, 107) !important;
    }
}