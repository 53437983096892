@import '../../../../app.scss';

.fila-superior {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .titulo-pagina {
        margin: 0 !important;
        font-weight: bold;
        color: $azul;
        font-size: 20px;
        line-height: 36px;
    }

    .cont-btns button {
        width:  auto;
        border-radius: 4px !important;
    }
}

// .fila-superior {
//     width: 100%;
//     display: flex;
//     // display: grid;
//     // grid-template-columns: 2fr 1fr;

//     .titulo-mes {
//         width: 100%;
//     }

//     .titulo-pagina {
//         width: 100%;
//         margin-top: 0 !important;
//         margin-bottom: 0 !important;
//         font-weight: bold;
//         color: $azul;
//         font-size: 22px;
//         margin-top: 2px;
//         // line-height: 50px;
//     }

//     > .cont-btns {
//         padding: 0 !important;
//         width: 100%;
//         text-align: right;
//     }
// }


.cont-btns button {
    border: none;
    border-radius: 10px !important;
    font-weight: bold;
    font-size: 15px;

    > i {
        margin-right: 7px !important;
        // padding-top: 1px !important;
    }

    &.btn-exportar:hover {
        background-color: #fd8661 !important;
    }

    &.btn-adj-var:hover {
        background-color: #0097a7  !important;
    }

    &.margen {
        margin-right: 5px !important;
    }
}


.cont-tabla {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 64vh !important;
    padding-bottom: 5px;
    // border: 1px solid blue;
}


.tabla-control-riego {
    margin-bottom: 0 !important;
    width: 100% !important;
    border-collapse: separate;
    // table-layout:fixed !important;

    th, td {
        text-align: center;
        border-radius: 0;
        padding: 5px;
        border: 1px solid #fff;
        font-size: 15px !important;
    }
    
    tr {
        transition: 0.1s;
    }

    thead tr th:first-child {
        min-width: 120px !important;
        text-align: center;
    }

    thead tr th {
        min-width: 120px !important;
    }

    td.color {
        background-color: #f2f2f2;
        transition: 0.1s !important;
    }

    > tbody tr:last-child td {
        background-color: #f2f2f2;
    }
}


.tabla-control-riego.tabla-general {
    td {
        color: $gris-oscuro;
    }

    > thead tr:first-child th {
        background-color: $gris;
        color: #fff;
        font-size: 15px;
    }

    > thead tr:first-child th.thcolor, 
    > thead tr:first-child th:first-child {
        background-color: #6f8e9e;
    }

    > thead tr:last-child th {
        background-color: #16539e;
        color: #fff;
        font-size: 15px;
    }

    > thead tr:last-child th.color-sub,
    > thead tr:last-child th:first-child  {
        background-color: #2566b4;
    }

    > tbody tr {
        border-bottom: 1px solid #284e7038;
        transition: 0.1s;
    }

    > tbody tr td:first-child {
        background-color: #d0ddec;
        font-weight: bold;
        color: #0e3566;
        transition: 0.1s;
    }

    > tbody tr:hover {
        background-color: #16539e25 !important;
    }

    > tbody tr:hover td.color {
        background-color: #91afd334 ;
    }

    > tbody tr:hover td:first-child {
        background-color: #16539e56 ;
    }

    > tbody tr:last-child td {
        font-weight: bold;
        background-color: #bccce0;
    }

    > tbody tr:last-child:hover td {
        font-weight: bold;
        background-color: #a9bfda;
    }
}

.tabla-control-riego.tabla-A {
    td {
        color: $gris-oscuro;
    }

    > thead tr:first-child th {
        background-color: #00838f;
        color: #fff;
        font-size: 15px;
    }

    > thead tr:first-child th.thcolor, 
    > thead tr:first-child th:first-child {
        background-color: #0993a0;
    }

    > thead tr:last-child th.color-sub,
    > thead tr:last-child th:first-child  {
        background-color: #63b1b8;
    }

    > thead tr:last-child th {
        background-color: #4199a1;
        color: #fff;
        font-size: 15px;
    }

    > tbody tr {
        border-bottom: 1px solid #284e7038;
        transition: 0.1s;
    }

    > tbody tr td:first-child {
        background-color: #b3d9df;
        font-weight: bold;
        color: #00838f;
        transition: 0.1s;
    }

    > tbody tr:hover {
        background-color: #e0f7fada !important;
    }

    > tbody tr:hover td:first-child {
        background-color: #8fc2ca !important;
    }

    > tbody tr:hover td.color, > tbody tr:last-child:hover td {
        background-color: #d1ebeeda ;
    }

    > tbody tr:last-child td {
        font-weight: bold;
    }
    
}

.tabla-control-riego.tabla-B {
    td {
        color: $gris-oscuro;
    }

    > thead tr:first-child th {
        background-color: #c25838;
        color: #fff;
        font-size: 15px;
    }

    > thead tr:first-child th.thcolor, 
    > thead tr:first-child th:first-child {
        background-color: #d46847;
    }


    > thead tr:last-child th {
        background-color: #e2937a;
        color: #fff;
        font-size: 15px;
    }

    > thead tr:last-child th.color-sub,
    > thead tr:last-child th:first-child  {
        background-color: #e9a793;
    }

    > tbody tr {
        border-bottom: 1px solid #284e7038;
        transition: 0.1s;
    }

    > tbody tr td:first-child {
        background-color: #ffccbc;
        font-weight: bold;
        color: #c25838;
        transition: 0.1s;
    }

    > tbody tr:hover {
        background-color: #ffccbc52 !important;
    }

    > tbody tr:hover td:first-child {
        background-color: #f1b29f !important;
    }

    > tbody tr:last-child:hover td, > tbody tr:hover td.color {
        background-color: #f1b29f33;
    }

    > tbody tr:last-child td {
        font-weight: bold;
    }
}

.preloader-wrapper {
    width: 10vh !important;
    height: 10vh !important;
    margin: 15px;
}

.circle-clipper .circle {
    border-width: 10px;
}

.spinner-color1 {
    border-color: none !important;
}

.selector-mes {
    margin-top: 5px;
    padding-top: 3px !important;
    height: auto !important;
    width: 250px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px !important;
    color: $gris !important;

    > option {
        font-size: 16px;
        border: none !important
    }

    &:focus {
        outline: none !important;
        border-color: $azul !important;
    }
}


#modal-descargar {
    width: 500px;
    .modal-content {
        padding: 0 !important;
    }

    .modal-content .cabecera-modal {
        text-align: center !important;
        background-color: $azul;
        padding-top: 10px;
        
        > i {
            font-size: 100px;
            color: #fff;
        }

        > button {
            padding: 0 !important;
            position: absolute;
            top: 15px;
            right: 15px;
            border: none !important;
            color: #ffffffb4;
            background-color: transparent !important;
        }

        > button:hover {
            color: #fff;
            cursor: pointer;
        }
    }

    .modal-content .selector-modal {
        width: 100%;
        padding: 20px;

        > h5 {
            text-align: center;
            width: 100%;
            color: $azul;
            font-weight: bold;
            margin-bottom: 0px !important;
            font-size: 20px !important;
        }

        > p {
            margin: 0 !important;
            color: $gris-oscuro;
            font-size: 15px;
            text-align: center;
        }

        > .cont-inputs-cr {
            display: flex;

            > div:first-child {
                margin-right: 10px;
            }

            > div {
                box-sizing: border-box !important;
                width: 100%;
            }
        }

        > .cont-inputs-cr div input {
            box-sizing: border-box !important;
            // width: 100%;
            font-size: 15px !important;
            color: $gris-oscuro;
            border: 1px solid #00000036;
            border-radius: 7px;
            height: 40px;
            margin-bottom: 10px;
            padding-left: 5px !important;

            &:focus {
                border-color: $azul;
                box-shadow: none !important;
                outline: none !important;
            }
        }
    }

    .cont-btns {
        padding: 0 !important;
        height: auto !important;
        display: grid;

        .btn-aceptar {
            width: 100%;
            box-shadow: none !important;
            border: none !important;
            border-radius: 5px !important;
            font-size: 15px;
            font-weight: bold;
            color: #fff;
        }

        .btn-aceptar:hover {
            background-color: #0962a7  !important;
        }
    }
}


.tabla-control-riego tbody tr td:first-child {
    position: sticky;
    z-index: 98;
    left: 0;
}

.tabla-control-riego thead tr:first-child th {
    position: sticky !important;
    z-index: 99;
    top: 0;
    left: 0;
}

.tabla-control-riego thead tr:last-child th {
    position: sticky !important;
    z-index: 99;
    top: 30px;
    left: 0;
}

.tabla-control-riego thead tr:first-child th:first-child {
    position: sticky !important;
    z-index: 100 !important;
    top: 0px;
    left: 0;
}

.tabla-control-riego thead tr:last-child th:first-child {
    position: sticky !important;
    z-index: 100 !important;
    top: 30px;
    left: 0;
}

// .tabla-control-riego thead tr:first-child th {
//     position: sticky;
//     z-index: 99;
//     top: 0;
// }

// .tabla-control-riego thead tr:last-child th {
//     position: sticky;
//     z-index: 99;
//     top: 38px;
// }

.cont-btns-tabs-riego {
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    > button {
        width: 100% !important;
        margin-right: 5px;
        border-radius: 7px;
        font-size: 15px;
        font-weight: bold;
        background-color: #ECF4FF !important;
        color: $azul;
    }

    > button:last-child {
        margin-right: 0px !important;
    }

    > button.activo {
        background-color: $azul !important;
        color: #fff;
    }
}

.tab-control-riego {
    // padding: 10px;
    display: none;
    // border: 1px solid #37474f27;
    border-radius: 12px;

    &.activo {
        display: block;
    }
}

.contenedor.cr {
    max-height: 85% !important;
    margin-bottom: 10px !important;
}