@import '../../../../app.scss';

// .fila-superior {
//     width: 100%;
//     display: grid;
//     grid-template-columns: 2fr 1fr;

//     > .titulo-pagina {
//         margin-top: 0 !important;
//         margin-bottom: 0 !important;
//         font-weight: bold;
//         color: $azul;
//         font-size: 30px;
//     }

//     > .cont-btns {
//         display: flex;
//         text-align: right;
//         justify-content: right !important;
//     }
// }

// .cont-btns button {
//     border: none;

//     &.btn-exportar {
//         margin-right: 10px;
//         border-radius: 12px;
//         font-weight: bold;
//         font-size: 15px;

//         > i {
//             margin-right: 7px !important;
//             padding-top: 1px !important;
//         }
//     }
// }

.input-rlix {
    margin-right: 10px;
    display: flex;
    border-radius: 120px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

    > input {
        border: 1px solid $azul3 !important;
        border-radius: 12px 0 0 12px !important;
        margin: 0 !important;
        height: 34px !important;
        padding-left: 10px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        text-align: center;
        background-color: #fff;
        color: $gris-oscuro;

        &:focus {
            box-shadow: none !important;
        }
    }

    > button {
        color: #fff;
        font-weight: bold;
        border: 1px solid $azul3 !important;
        border-radius: 0 30px 30px 0;
        background-color: $azul3 !important;
        height: 36px;
        padding-right: 15px;
        padding-left: 15px;
        transition: 0.2s;

        &:hover {
            background-color: $azul4 !important;
            cursor: pointer;
        }
    }
}

.ciclo-rlix {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    font-size: 20px;
    font-weight: bold;
    color: rgba(0,0,0,0.5);
}

// .cont-tab {
//     height: 70vh;
// }

.cont-grafs {
    max-height: 68vh !important;
    padding-right: 5px;
}


.cont-tabla-rlix {
    max-height: 65vh;
}

.cont-tabla-rlix, .cont-grafs {
    margin-top: 15px;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    table-layout: fixed;
}

.cont-tabla-rlix::-webkit-scrollbar,
.cont-grafs::-webkit-scrollbar {
    width: 10px !important;
    height: 10px;
}

/* Track */
.cont-tabla-rlix::-webkit-scrollbar-track,
.cont-grafs::-webkit-scrollbar-track {
     background: #f7f7f7 !important;
     border-radius: 30px;
}
  
/* Handle */
.cont-tabla-rlix::-webkit-scrollbar-thumb,
.cont-grafs::-webkit-scrollbar-thumb {
    background: $gris !important;
    border-radius: 30px;
}

.tabla-rlix {

    thead tr {
        position: sticky;
        top: 0;
    }
    td, th {
        border-radius: 0 !important;
        padding: 5px;
        text-align: center;
        border: 1px solid #d8d8d8;
    }

    th {
        background-color: $negro;
        color: #fff;
        box-shadow: inset 0px -4px 0px -1px #7c7c7c;
    }

    td {
        color: $negro;
    }

    tr:hover  {
        td {
            background-color: #3677b023 !important;
            font-weight: bold;
        }
    }
}


.cont-grafs {
    overflow-x: hidden !important;
    padding-top: 20px;
}

.selector-ciclo {
    font-size: 18px;
    font-weight: bold;
    color: $gris-oscuro;
    margin-top: -10px;
    width: 200px;
}

.cont-btns-tabs-rlix {
    width: 100%;
    display: flex;
    
    button {
        width: 100%;
        box-shadow: none;
        border-radius: 5px;
        font-size: 15px;
        font-weight: bold;
        background-color: #ECF4FF !important;
        color: $azul;
    }

    button:first-child {
        margin-right: 5px;
    }

    button.activo {
        background-color: $azul !important;
        color: #fff;
    }
}

.tab-rlix {
    display: none;

    &.activo {
        display: block;
    }
}

.estado-rlix {
    padding: 3px 10px 3px 10px;
    border-radius: 4px;
    font-size: 15px !important;

    &.Vacio {
        background-color: #e6e7f3;
        color: $gris-oscuro;
    }

    &.Inicio_Apilamiento, &.Fin_Apilamiento {
        background-color: #778d5c;
        color: #fff;
    }

    &.Ruteo {
        background-color: #b6703b;
        color: #fff;
    }

    &.Armado_Parrilla {
        background-color: #C5A463;
        color: #fff;
    }

    &.Humectacion, &.Humectación {
        background-color: #3e5e7c;
        color: rgba(255,255,255);
    }

    &.Riego {
        background-color: #427975;
        color: rgba(255,255,255);
    }

    &.Detenido_por_Fuerza_Mayor {
        background-color: #925d5d;
        color: rgba(255,255,255);
    }

    &.Drenaje {
        background-color: #706072;
        color: rgba(255,255,255);
    }

    &.Desarme_de_Parrilla, &.Desarme_de_Parilla  {
        background-color: #434E5F;
        color: rgba(255,255,255);
    }

    &.Inicio_Derripado , &.Fin_Derripado {
        background-color: #484D52;
        color: rgba(255,255,255);
    }
}