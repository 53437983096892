@import '../../../app.scss';

.titulo-desarrollo {
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 10px;
    position: absolute;
    font-size: 20px;
    top: 5px !important;
    color: #ffffff6b;
}

.material-symbols-outlined {
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }
  
.sidebar {
    height: 100vh !important;
    width: 300px;
    z-index: 999;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: #303030 !important; 
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    transition: .3s ease-in-out;
    user-select: none;
    overflow-y: auto;
    overflow-x: hidden;
}

.contenedor-btns-sidebar button, .contenedor-btns-sidebar button:focus {
    background-color: transparent;
    border: none !important;
    width: 40px;
    height: 40px;
    float: right;
    font-size: 20px;
    margin-top: 8px;
    margin-right: 10px;
    transition: 0.2s;

    &.btn-abrir {
        color: #fff;
        // background-color: $azul;
        border-radius: 5px;
    }

    &.btn-cerrar {
        color: rgba(255,255,255,0.3)
    }

    &.btn-cerrar:hover, &.btn-abrir:hover {
        color: rgba(255,255,255);
        cursor: pointer;
    }
}

.cont-btn-cerrar-sesion {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 20px 20px 10px 20px;

    > button {
        transition:width 0.2s !important;
        font-weight: bold;
        border-radius: 7px;
        background-color: $azul;
        color: #ffff ;
    }
}

.cont-logo-empresa {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center !important;

    > img {
        width: 100%;
    }
}

.cont-sesion {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;

    > .cont-icon-centinela {
        text-align: center;
        
        > img {
            margin-left: 35px;
            width: 180px;
        }
    }

    > p {
        margin: 0 !important;
        color: #fff;

        &.nombre {
            font-weight: bold;
            font-size: 16px;
            text-transform: uppercase;
        }

        &.rol {
            font-size: 15px;
            color: #ffffffa4;
        }
    }
}


.collection {
    border: none;
}

.collection-item {
    height: 55px;
    background-color: transparent !important;
    border-bottom: 1px solid rgba(0,0,0,0.1) !important;

    > label {
        color: #fff !important;
        font-size: 16px;
        line-height: 34px !important;
    }
    
    &.active {
        background-color: $azul !important;
    }

    &.active:hover {
        background-color: $azul !important;
    }

    &.active:hover > i {
        background-color: rgb(0 0 0 / 30%);
    }

    &:hover {
        background-color: rgba(0,0,0, 0.3) !important;
    }

    &:hover > i {
        background-color: $azul;
    }

    > i {
        color: #fff;
        transition: 0.2s;
        margin-right: 10px;
        top: -5px !important;
        background-color: rgb(0 0 0 / 30%);
        border-radius: 7px;
        padding: 5px;
    }
}

.collection-item.sub.active {
    background-color: #01589b6e !important;
}


.collapsible {
    margin: 0;
    border: none;
}


.collapsible-header {
    background-color: transparent !important;
    border-bottom: 1px solid rgba(0,0,0,0.1) !important;
    padding: 10px 20px;

    &.active {
        background-color: $azul !important;
    }

    &.active:hover {
        background-color: $azul !important;
    }

    &.active:hover > i {
        background-color: rgb(0 0 0 / 30%);
    }

    &:hover {
        background-color: rgba(0,0,0, 0.3) !important;
    }

    &:hover > a i {
        background-color: $azul;
    }

    > a, > a label {
        color: #fff !important;
        font-size: 16px;
        line-height: 34px !important;
        width: 100%;
    }

    > a i {
        transition: 0.2s;
        top: -5px !important;
        background-color: rgb(0 0 0 / 30%);
        border-radius: 7px;
        padding: 5px;
        width: auto;
    }

    > a i.right {
        background-color: transparent !important;
        margin: 0;
    }
}

.collapsible-body {
    padding: 0 !important;
    padding-left: 48px !important;
    background-color: rgba(0,0,0, 0.2);
    border: none;
    
    > .collection a {
        color: #fff !important;
        height: auto !important;
    }
}
