
@media (max-width: 1450px) {
    .contenedor-tablas-sx {
        grid-template-columns: 1fr;

        > div:first-child {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
        }
    }

    .tabla-ewsx.consumo {
        > tbody tr td:nth-child(1) {
            width: 200px;
        }
    
        > tbody tr td:nth-child(2) {
            background-color: rgba(179, 217, 223, 0.5) !important;
            width: 150px;
        }
    
        > tbody tr td:nth-child(3) {
            background-color: rgba(255, 204, 189, 0.5);
            width: 150px;
        }
    }
}

@media (max-width: 1200px) {
    .cont-ewsx {
        display: block;

        > div {
            max-height: 100%;
        }
    }
}


@media (max-width: 1000px) {
    #tab-lixiviacion {
        grid-template-columns: 1fr !important;
    }
}