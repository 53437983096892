@import '../../../../app.scss';

.cont-tabla.historico {
    max-height: 70vh !important;
    overflow-x: auto !important;
}

.tabla-historico {
    thead {
        position: sticky;
        top: 0;
        z-index: 100 !important;
    }

    thead tr th:first-child {
        position: sticky;
        left: 0 !important;
        top: 0;
    }

    tbody tr td:first-child {
        z-index: 90 !important;
        position: sticky;
        left: 0;
    }

    th, td {
        padding: 5px;
        text-align: center;
        border: 1px solid #fff;
        border-radius: 0 !important;
        font-size: 14px !important;
    }

    th {
        background-color: $gris;
        color: #fff;
    }

    td {
        color: #333333;
        background-color: #fafafa;
        transition: 0.1s;
    }

    tbody tr:nth-child(even) td {
        background-color: #f0f0f0;
    }

    tr:hover td {
        background-color: #cfdceb !important; 
        font-weight: bold;
    }
}

.tabla-historico th {
    &.min {
        min-width: 100px;
    }

    &.max {
        min-width: 150px;
    }

}