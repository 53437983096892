@import '../../../../app.scss';

.tday-tabla {
    table-layout: fixed;

    td, th {
        text-align: center !important;
        padding: 3px;
        font-size: 16px;
    }

    td {
        color: $gris-oscuro !important;
        background-color: #f2f2f2;
        border-bottom: 1px solid #fff;
        font-weight: bold;
    }

    thead tr th:nth-child(1) {
        background-color: #e07b40;
        color: #fff;  
        border-left: 0 !important;  
        border-bottom: 5px solid #fff;
        border-right: 5px solid #fff;
    }

    thead tr th:nth-child(2) {
        background-color: #0097a7;
        color: #fff;  
        border-left: 0 !important;  
        border-bottom: 5px solid #fff;
        border-right: 5px solid #fff;
    }

    thead tr th:nth-child(3) {
        background-color: #01579b;
        color: #fff;  
        border-left: 0 !important;  
        border-bottom: 5px solid #fff;
        border-right: 0 !important;
    }


    tbody tr td:nth-child(1) {
        background-color: #e07b4017;
        border-left: 0 !important;  
        border-right: 5px solid #fff;
    }

    tbody tr td:nth-child(2) {
        background-color: #0097a717;
        border-left: 0 !important;  
        border-right: 5px solid #fff;
    }

    tbody tr td:nth-child(3) {
        background-color: #01579b17;
    }
}

.tab-informe .apexcharts-legend-series span {
    font-size: 15px !important;
    font-weight: bold;
    margin-right: 10px;
}

.tab-informe .apexcharts-legend-series span:last-child {
    margin-right: 0 !important;
}

.cont-tabla-nomina {
    max-height: 350px;
    overflow-y: auto;
}

.cont-tabla-nomina table {
    background-color: #fff;
    border-collapse: separate !important;

    td, th {
        border: 1px solid #fff;
        border-radius: 0 !important;
        white-space: nowrap;
    }

    thead tr th {
        padding: 7px !important;
        background-color: $azul;
        border-radius: 0;
        text-align: center;
        color: #fff;
        position: sticky;
        top: -1px;
        // box-shadow: inset 0 2px 0 #fff, inset 0 -2px 0 #fff;
    }

    tbody tr td:first-child {
        font-weight: bold;
        text-align: left !important;
        background-color: #01589b2d;;
    }

    tbody tr td {
        transition: 0.1s !important;
        padding: 5px;
        background-color: #f7f7f7;
        color: $gris-oscuro;
        text-align: center;
    }

    .colorear td {
        background-color: #f2f2f2;
    }

    .colorear td:first-child {
        background-color: #01589b41;
    }

    tbody tr:hover {
        td:first-child {
            background-color: #01589b70 !important;
        }

        td {
            background-color: #01589b25;
        }
    }

    tbody tr:last-child {
        position: sticky;
        bottom: 0;

        td {
            background-color: #f8e2d5;
            font-weight: bold;
            border-top: 2px solid #fff !important;
        }

        td:first-child {
            text-align: left !important;
            color: #fff !important;
            background-color: #e07b40 !important;
        }

        &:hover {
            td:first-child {
                background-color: #d47036 !important;
            }
    
            td {
                background-color: rgb(238, 202, 181)
            }
        }
    }
}

.cont-tabla-total-nomina table {
    background-color: #fff;

    td, th {
        border: 2px solid #fff;
        border-radius: 0 !important;
    }

    tbody tr td {
        background-color: #e07b4038 !important;
        font-weight: bold;
        border: 2px solid #fff;
        border-radius: 0 !important;
        transition: 0.1s !important;
        padding: 5px;
        color: $gris-oscuro;
        text-align: center;
    }

}

.cont-dos-tablas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.linea-cont-tabla {
    border: 2px solid #dee4e7; 
    border-radius: 15px;
    padding: 10px;
}

.contenido-cosecha.sx table {
    td, th {
        padding: 5px !important;
    }
}

.tabla-flujos {
    margin-bottom: 15px;

    th, td {
        border: 2px solid #fff !important;
    }

    td {
        background-color: #f7f7f7;
        transition: 0.1s !important;
        color: $gris-oscuro !important;
    }

    tr {
        border-bottom: none !important;
    }

    > thead tr th {
        text-align: center;
        padding: 7px;
        border-radius: 0px;
        color: #fff;
    }

    > thead tr th.celda-vacia {
        background-color: #fff !important;
    }

    > thead tr:first-child th {
        background-color: $gris;
    }

    > thead tr:last-child th {
        background-color: #3677b0;
    }

    > thead tr:last-child th:first-child {
        width: 130px;
    }

    > thead tr:last-child th {
        width: 120px;
    }

    > tbody tr td {
        padding: 7px;
        border-radius: 0px !important;
    }

    > tbody tr td:first-child {
        font-weight: bold;
        background-color: #3677b077;
        color: $gris-oscuro;
    }

    > tbody tr:hover {
        background-color: #3677b023 !important;
    }


    tbody tr td:first-child {
        text-align: left !important;
    }

    td {
        text-align: center;
    }
}

.tabla-flujos.flujos {
    > tbody tr td:first-child {
        background-color: #607d8b0e;
    }

    td.turno-a-flujos {
        background-color: #b3d9df4d;
    }

    td.turno-b-flujos {
        background-color: #ffccbd50;
    }
}

.tabla-flujos.turnos {
    > thead tr th:first-child {
        background-color: transparent !important;
    }
}


.tabla-flujos.turnob {
    > thead tr:last-child th {
        background-color: #ffccbc;
        color: #bf360c;
    }

    > tbody tr td:first-child {
        background-color: #ffccbd50;
    }

    > tbody tr:hover td {
        background-color: #ffccbd50;

        &.celda-no-valida {
            background-color: #607d8b38  !important;
        }
    }
}

.tabla-flujos.turnoa {
    > thead tr:last-child th {
        background-color: #b3d9df;
        color: #006064;
    }

    > tbody tr td:first-child {
        background-color: #b3d9df4d;
    }

    > tbody tr:hover td {
        background-color: #b3d9df4d;

        &.celda-no-valida {
            background-color: #607d8b38  !important;
        }
    }
}

.tabla-flujos.turnob, .tabla-flujos.turnoa {
    tr:last-child td:last-child {
        font-weight: bold;
    }
}

.cont-input {
    display: flex;

    > input {
        padding-left: 32px;
        margin: 0 !important;
        border: 1px solid $gris;
        border-radius: 7px 0 0 7px;
        height: 30px;
        text-align: center;
        background-color: rgba(255,255,255,0.7);
        transition: 0.2s;
        color: $gris-oscuro !important;
        font-size: 16px;

        &:focus {
            box-shadow: none !important;
            border-color: $gris !important;
            background-color: #fff;
            border-color: $azul;
        }
    }

    > button {
        height: 32px;
        margin: 0;
        box-shadow: none !important;
        border-radius: 0px 0px 0px 0px !important;
        padding-left: 5px;

        &:hover {
            background-color: #7694a3 !important;
        }

        > i {
            margin: 0 !important;
            font-size: 16px;
            line-height: 20px;
        }
    }

    > button:disabled {
        background-color: #7d8f97 !important;
        color: #d1d1d1 !important;
    }

    > button:last-child {
        border-radius: 0 7px 7px 0 !important;
    }
}

.cont-dos-tablas.tabla-fila {
    box-sizing: border-box !important;
}

.tabla-fila {
    width: 100%; 
    border-collapse: separate;

    td {
        padding: 5px;
        width: 50%;
        border: 2px solid #fff;
    }

    > tbody tr {
        border: 0;
    }

    > tbody tr td:first-child {
        background-color: #3677b0;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        // border: 1px solid #3677b0;
    }

    > tbody tr td:last-child {
        text-transform: uppercase;
        background-color: #f2f2f2;
        // border-bottom: 1px solid #3677b0;
        // border-right: 1px solid #3677b0;
        // border-top: 1px solid #3677b0;
    }


}

.tabla-ewsx {
    width: 99%; 
    margin-bottom: 15px;
    border-collapse: collapse;

    th, td {
        border: 2px solid #fff;
        padding: 7px !important;
        border-radius: 0;
    }

    td {
        color: $gris-oscuro !important;
        text-align: center;
    }

    > tbody tr td:first-child {
        text-align: left !important;
        font-weight: bold;
        background-color: rgba(96, 125, 139, 0.0549019608);
    }

    > tbody tr {
        transition: 0.1s;
    }

    > tbody tr:hover {
        background-color: #3677b023 !important;
    }
    
}

.tabla-ewsx.simple {
    th {
        background-color: $gris;
        color: #fff;
        text-align: center;
    }
}

.tabla-ewsx.doble {
    thead tr:first-child th {
        background-color: $gris;
        color: #fff;
        text-align: center;
    }

    > thead tr:last-child th {
        border-radius: 0 !important;
        background-color: #3677b0;
        text-align: center;
        color: #fff;
    }
}

.tabla-flujos.colores-turnos,
.tabla-ewsx.colores-turnos {
    > thead tr th {
        background-color: $gris;
    }

    > tbody tr td:first-child {
        background-color: #607d8b21 !important;
    }

    .turno-a {
        background-color: rgba(179, 217, 223, 0.5) !important;
    }

    .turno-b {
        background-color: rgba(255, 204, 189, 0.5);
    }

}

.tabla-ewsx.eficiencia, .tabla-ewsx.eficiencia-extraccion,
.tabla-ewsx.teorica {
    > thead tr th:first-child {
        width: 250px !important;
    }

    > tbody tr td:first-child {
        width: 250px !important;
    }

    td {
        color: $gris-oscuro !important;
        background-color: #f7f7f7;
    }

    > tbody tr td:first-child {
        font-weight: bold;
        background-color: #d2e2ed;
    }

    > tbody tr {
        transition: 0.1s;
    }

    > tbody tr:hover {
        td:first-child {
            background-color: #3677b036 !important;
        }

        td {
            background-color: #3677b011 !important;
        }
    }
}

.tabla-ewsx.consumo {
    > tbody tr td:nth-child(1) {
        width: 200px;
    }

    > tbody tr td:nth-child(2) {
        background-color: rgba(179, 217, 223, 0.5) !important;
        width: 220px;
    }

    > tbody tr td:nth-child(3) {
        background-color: rgba(255, 204, 189, 0.5);
        width: 220px;
    }
}

.tabla-ewsx.eficiencia {
    th {
        min-width: 300px;
    }
}

.celda-no-valida {
    background-color: #607d8b38  !important;
}


td {
    transition: 0.1s !important;
}

.input-cosecha-error {
    border: 1px solid #bf360c !important;
}