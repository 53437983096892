@import '../../../../app.scss';

.cont-tab {
    padding-top: 10px;
}


.cont-tabla {
    width: 100%;
    max-height: 70vh;
    overflow-x: auto;
    overflow-y: auto;
    padding-bottom: 5px;
}

.tabla-rom {
    transform-style: preserve-3d;
    background-color: #fff;
    border-collapse: separate;
    
    td, th {
        border: 1px solid #fff;
        border-radius: 0 !important;
    }

    > thead {
        transform: translate3d(0, 0, 1px);
    }

    > tbody {
        transform: translate3d(0, 0, 0);
    }

    > thead tr th {
        text-align: center;
        text-transform: uppercase;
        padding: 7px;
        color: #fff;
        border-radius: 0 !important;
    }

    > thead tr th.dif {
        background-color: #728d9b !important;
    }

    > thead tr:first-child  th {
        background-color: $gris;
    }

    > thead tr:last-child th {
        background-color: #3677b0;
        min-height: 120px !important;
        font-size: 14px;
    }

    > thead tr:last-child th.claro {
        background-color: #4b8cc5 !important;
    }

    > tbody tr td {
        padding: 5px;
        text-align: center;
    }

    > tbody tr, > tbody tr td {
        transition: 0.1s ;
    }


    > tbody tr td.fecha {
        font-weight: bold;
        background-color: #728d9b;
        color: #fff;
        min-width: 120px !important;
        position: sticky;
        left: 0;
    }

    > tbody tr td {
        background-color: #e9e9e9;
    }

    > tbody tr td:nth-child(2), > tbody tr td:nth-child(3), > tbody tr td:nth-child(4),
    > tbody tr td:nth-child(8), > tbody tr td:nth-child(9), > tbody tr td:nth-child(10), > tbody tr td:nth-child(11),
    > tbody tr td:nth-child(16), > tbody tr td:nth-child(17) {
        background-color: #f2f2f2;
    }

    > thead tr {
        position: sticky;
    }

    > thead tr:first-child {
        top: 0;
        border-bottom: $gris-oscuro;
    }

    > thead tr th.th-fecha {
        position: sticky;
        left: 0;
        z-index: 99 !important;
    }

    > thead tr:last-child {
        top: 38px !important;
    }

}

.tabla-rom.tabla-general {
    > thead tr:first-child th {
        background-color: #607d8b;
        color: #fff;
        font-size: 16px;
    }

    > thead tr:last-child th {
        background-color: #16539e;
        min-height: 120px !important;
        font-size: 14px;
    }

    > thead tr:last-child th.claro {
        background-color: #2566b4 !important;
    }

    > thead tr th.dif {
        background-color: #6f8e9e !important;
    }

    > tbody tr td.fecha {
        font-weight: bold;
        background-color: #d0ddec;
        color: $gris-oscuro;
        min-width: 120px !important;
        position: sticky;
        left: 0;
    }

    > tbody tr {
        &:hover, &:hover > td {
            background-color: #16539e23 !important;
        }

        &:hover >  td:nth-child(5), &:hover > td:nth-child(6), &:hover > td:nth-child(7),
        &:hover >  td:nth-child(11), &:hover > td:nth-child(12), &:hover > td:nth-child(13) {
            background-color: #16539e3d !important;
        }

        &:hover > td.fecha {
            background-color: #16539e63 !important;
        }
    }
}

.tabla-rom.tabla-A {
    > thead tr:first-child th {
        background-color: #00838f;
        color: #fff;
        font-size: 16px;
    }

    > thead tr:last-child th {
        background-color: #429ba3;
        min-height: 120px !important;
        font-size: 14px;
    }

    > thead tr:last-child th.claro {
        background-color: #53b2bb !important;
    }

    > thead tr th.dif {
        background-color: #429ba3 !important;
    }

    > tbody tr td.fecha {
        font-weight: bold;
        background-color: #429ba356;
        color: $gris-oscuro;
        min-width: 120px !important;
        position: sticky;
        left: 0;
    }

    > tbody tr {
        &:hover, &:hover > td {
            background-color: #429ba323 !important;
        }

        &:hover >  td:nth-child(5), &:hover > td:nth-child(6), &:hover > td:nth-child(7),
        &:hover >  td:nth-child(11), &:hover > td:nth-child(12), &:hover > td:nth-child(13) {
            background-color: #429ba342 !important;
        }

        &:hover > td.fecha {
            background-color: #429ba393 !important;
        }
    }
}

.tabla-rom.tabla-B {
    
    > thead tr:first-child th {
        background-color: #c25838;
        color: #fff;
        font-size: 16px;
    }

    > thead tr:last-child th {
        background-color: #e0896e;
        min-height: 120px !important;
        font-size: 14px;
    }

    > thead tr:last-child th.claro {
        background-color: #e69981 !important;
    }

    > thead tr th.dif {
        background-color: #da6846 !important;
    }

    > tbody tr td.fecha {
        font-weight: bold;
        background-color: #e0896e49;
        color: $gris-oscuro;
        min-width: 120px !important;
        position: sticky;
        left: 0;
    }

    > tbody tr {
        &:hover, &:hover > td {
            background-color: #e0896e25 !important;
        }

        &:hover >  td:nth-child(5), &:hover > td:nth-child(6), &:hover > td:nth-child(7),
        &:hover >  td:nth-child(11), &:hover > td:nth-child(12), &:hover > td:nth-child(13) {
            background-color: #e0896e48 !important;
        }

        &:hover > td.fecha {
            background-color: #e0896e91 !important;
        }
    }
}

