@import '../../../../app.scss';

.tabla-estados {
    border-collapse: separate !important;
    border: 1px solid #a1a1a13a;

    td {
        padding: 5px !important;
        border-radius: 0 !important;
        border: 1px solid #fff;
        color: $gris-oscuro !important;
        font-weight: bold;
        transition: 0.1s;
    }

    tbody tr td:nth-child(2), tbody tr td:last-child {
        text-align: center;
        font-weight: normal !important;
    }

    tbody tr {
        background-color: rgba(242,242,242,0.5);
    }

    tbody tr.impar {
        background-color: #f2f2f2 !important;
    }

    tr:hover {
        td {
            background-color: #01589b25 !important;
        }
    }
}

.cont-tablas-resumen.met .tabla-resumen {
    border-collapse: separate;
    border-spacing: 0px !important;
    background-color: #fff;

    > thead tr th, > tbody tr td {
        border-radius: 0;
        border: 1px solid #ffffff !important;
    }

    > thead {
        position: sticky;
        top: 0;
        z-index: 5;
    }

    > thead tr th {
        text-align: center;
        color: #fff;
        background-color: #3677b0;
        font-size: 15px;
        padding: 5px;
    }

    > thead tr:first-child th {
        background-color: $gris;
    }

    > tbody tr td:first-child {
        width: 150px;
    }

    > tbody tr td:first-child button {
        background-color: #3677b031 !important;
    }   

    > tbody tr td:first-child button:hover {
        background-color: $azul !important;
            color: #fff !important;
    }   

    > tbody tr td {
        padding: 0 !important;

        > button {
            width: 100%;
            height: 35px;
            margin: 0 !important;
            border-radius: 0 !important;
            border: none !important;
            box-shadow: none !important;
            font-weight: bold;
            font-size: 15px;
            
            color: $azul;
            transition: 0.1s;
        }
    }

    > tbody tr td {
        padding: 5px;
        text-align: center;
    }

    .estado {
        text-transform: none !important;
        font-weight: bold !important;
        transition: 0.1s;

        &.Vacio {
            background-color: #e6e7f3;
            color: $gris-oscuro;

            &:hover {
                background-color: #f4f5fc;
            }
        }

        &.Inicio_Apilamiento , &.Fin_Apilamiento {
            background-color: #778d5c;
            color: #fff;
            &:hover {
                background-color: #96b374;
            }
        }

        &.Ruteo {
            background-color: #b6703b;
            color: #fff;
            &:hover {
                background-color: rgb(223, 149, 93);
            }
        }

        &.Armado_Parrilla {
            background-color: #C5A463;
            color: #fff;
            &:hover {
                background-color: rgb(236, 203, 136);
            }
        }

        &.Humectacion, &.Humectación {
            background-color: #3e5e7c;
            color: rgba(255,255,255);
            &:hover {
                background-color: #4b7296;
            }
        }

        &.Riego {
            background-color: #427975;
            color: rgba(255,255,255);
            &:hover {
                background-color: #559994;
            }
        }

        // &.En_riego_refino {
        //     background-color: #88c3cf;
        //     color: rgba(0,0,0,0.5);
        //     &:hover {
        //         background-color: #9bd6e2;
        //     }
        // }

        &.Detenido_por_Fuerza_Mayor {
            background-color: #925d5d;
            color: rgba(255,255,255);
            &:hover {
                background-color: #ad7070;
            }
        }

        &.Drenaje {
            background-color: #706072;
            color: rgba(255,255,255);
            &:hover {
                background-color: #9f89a1;
            }
        }

        &.Desarme_de_Parrilla, &.Desarme_de_Parilla  {
            background-color: #434E5F;
            color: rgba(255,255,255);
            &:hover {
                background-color: #64748b;
            }
        }

        &.Inicio_Derripado, &.Fin_Derripado {
            background-color: #484D52;
            color: rgba(255,255,255);
            &:hover {
                background-color: #697077;
            }
        }
    }
}

.cont-tabla-resumen.oxe .tabla-resumen {
    border-collapse: separate;
    width: 100% !important;
    background-color: #fff;
    table-layout: fixed;

    > thead tr th, > tbody tr td {
        border-radius: 0;
        border: 1px solid #ffffff !important;
    }

    > thead {
        position: sticky;
        top: 0;
        z-index: 5;
    }

    > thead tr th {
        text-align: center;
        color: #fff;
        background-color: $gris;
        font-size: 15px;
        padding: 7px;
    }

    > tbody tr td.bloque{
        color: #fff;
        background-color: $gris;
        font-weight: bold;
    }

    > tbody tr td.modulo{
        color: #fff;
        background-color: #3677b0;
        font-weight: bold;
    }

    > tbody tr td {
        padding: 0;
        text-align: center;
    }

    > tbody tr td.bloque, > thead tr th:first-child {
        position: sticky;
        left: 0;
        z-index: 4;
    }

    > tbody tr td.modulo, > thead tr th:nth-child(2) {
        position: sticky !important;
        left: 50px;
        z-index: 4;
    }

    .btn-modulo {
        width: 100%;
        height: 37px;
        margin: 0 !important;
        border-radius: 0 !important;
        border: none !important;
        box-shadow: none !important;
        font-weight: bold;
        font-size: 16px;
        transition: 0.2s;
    
        &:hover {
            transform: scale(1.1);
            z-index: 2;
        }
    
        &.Vacio {
            background-color: #e6e7f3;
            color: $gris-oscuro;
    
            &:hover {
                background-color: #f4f5fc;
            }
        }
    
        &.Inicio_Apilamiento, &.Fin_Apilamiento {
            background-color: #778d5c;
            color: #fff;
            &:hover {
                background-color: #96b374;
            }
        }
    
        &.Ruteo {
            background-color: #b6703b;
            color: #fff;
            &:hover {
                background-color: rgb(223, 149, 93);
            }
        }
    
        &.Armado_Parrilla {
            background-color: #C5A463;
            color: #fff;
            &:hover {
                background-color: rgb(236, 203, 136);
            }
        }
    
        &.Humectacion, &.Humectación {
            background-color: #3e5e7c;
            color: rgba(255,255,255);
            &:hover {
                background-color: #4b7296;
            }
        }
    
        &.Riego {
            background-color: #427975;
            color: rgba(255,255,255);
            &:hover {
                background-color: #4e8f8a;
            }
        }
    
        // &.En_riego_refino {
        //     background-color: #88c3cf;
        //     color: rgba(0,0,0,0.5);
        //     &:hover {
        //         background-color: #9bd6e2;
        //     }
        // }
    
        &.Detenido_por_Fuerza_Mayor {
            background-color: #925d5d;
            color: rgba(255,255,255);
            &:hover {
                background-color: #ad7070;
            }
        }
    
        &.Drenaje {
            background-color: #706072;
            color: rgba(255,255,255);
            &:hover {
                background-color: #9f89a1;
            }
        }
    
        &.Desarme_de_Parrilla, &.Desarme_de_Parilla  {
            background-color: #434E5F;
            color: rgba(255,255,255);
            &:hover {
                background-color: #64748b;
            }
        }
    
        &.Inicio_Derripado , &.Fin_Derripado {
            background-color: #484D52;
            color: rgba(255,255,255);
            &:hover {
                background-color: #697077;
            }
        }
    
    }
}