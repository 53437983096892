@import '../../../../app.scss';

.fila-sup-resumen, .filtros.resumen {
    display: flex;
    width: 100%;
    box-sizing: border-box !important;

    .btn-dl {
        min-width: 150px;
        color: #fff;
        font-weight: bold;
        border-radius: 5px;

        i {
            margin-right: 0px !important;
        }
    }
}


.fila-sup-resumen {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box !important;
}

.filtro-select {
    min-width: 250px !important;
    margin-right: 10px;

    &.historico {
        min-width: 160px !important;
    }

    label {
        color: #333333;
        font-size: 14px;
        font-weight: bold;
    }

    select {
        font-size: 15px !important;
        color: #333333 !important;
        padding: 5px !important;
        min-height: auto;
        height: auto;
        border-color: #3333333d;
        border-radius: 5px;
        transition: 0.1s;

        &:focus {
            border-color: $azul;
            box-shadow: none !important;
            outline: none !important;
        }
    }
}

.btn-orden {
    min-width: 100px !important;
    max-width: 100px !important;

    label {
        color: #333333;
        font-size: 14px;
        font-weight: bold;
    }

    i {
        margin-right: 0 !important;
        transform: rotate(-90deg);
    }
}

.filtros-historicos .filtro { 
    box-sizing: border-box !important;
    label {
        color: #333333;
        font-size: 14px;
        font-weight: bold;
    }

    margin-bottom: 10px;

    select {
        font-size: 15px !important;
        color: #333333 !important;
        padding: 5px !important;
        border-color: #3333333d;
        border-radius: 5px;
        transition: 0.1s;
        height: auto;
        
        &.select-pilas {
            height: 30vh !important;
        }

        &:focus {
            border-color: $azul;
            box-shadow: none !important;
            outline: none !important;
        }
    }
}

.cont-checks {
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 10px;
    max-height: 180px !important;
    overflow-y: auto;
    overflow-x: hidden !important;

    p {
        text-align: center;
        font-size: 14px;
        color: #3333337c;
    }
}

.filtros-historicos button {
    text-transform: none !important;
    width: 100%;
    border-radius: 5px;
    font-weight: bold;
}

[type="checkbox"].chk-historico,
[type="checkbox"].chk-historico:checked,
[type="checkbox"].chk-historico:not(checked) {
  opacity: 1;
  position: relative;
}

[type="checkbox"].chk-historico+span::before,
[type="checkbox"].chk-historico+span::after,
[type="checkbox"].chk-historico:checked+span::before,
[type="checkbox"].chk-historico:checked+span::after {
  display: none;
}


.select-pilas::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.select-pilas::-webkit-scrollbar-track {
    background: #607d8b25;
}

/* Handle */
.select-pilas::-webkit-scrollbar-thumb {
    background: $gris-oscuro;
}

/* Handle on hover */
.select-pilas::-webkit-scrollbar-thumb:hover {
    background: $gris-oscuro;
}

.filtros-historicos .btn-dl {
    margin-bottom: 10px;
}

.filtro.orden button {
    margin-top: 10px;
    i {
        transform: rotate(-90deg);
    }
}