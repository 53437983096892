@media (max-width: 1570px) {
    .cont-login {
        width: 80%;
        grid-template-columns: 65% 35%;
    }
}


@media (max-width: 1280px) {
    .cont-login {
        grid-template-columns: 60% 40%;
    }
}



@media (max-width: 1050px) {
    .cont-login {
        grid-template-columns: 55% 45%;
    }
}



@media (max-width: 880px) {
    .contenedor-autentificacion {
        background-image: url('../../assets/img/fondo_login.jpg') !important;
        background-size: cover;
        background-position: 0px 0px;
        background-repeat: repeat-x;
        animation: animatedBackground 150s linear infinite alternate;
    }

    .cont-login {
        display: block;
        width: 85%;

        > .img-autentificacion {
            display: none;
        }


        > .card {
            height: auto;;
            border-radius: 25px;

            > img {
                width: 80% !important;
                margin-top: 10px;
            }
        }
    }
} 