@import '../../../../app.scss';

.contenedor.sim {
    height: 79vh !important;
}

.react-flow {
    height: 75vh !important;
    border-radius: 15px !important;
}

.react-flow__node {
    background-color: #fff;
    border: none !important;
    border-radius: 12px !important;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px 0px;
    padding: 15px !important;
}


.tabla-nodo {
    border-collapse: separate;
    td, th {
        border: 1px solid #fff;
        border-radius: 0 !important;
        padding: 5px !important;
    }

    th {
        text-align: center !important;
        color: #fff !important;
        background-color: $gris !important;
    }

    td {
        background-color: #f2f2f2 !important;
        text-align: center;
        color: $gris-oscuro;
    }

    &.pred {
        th {
            background-color: $azul !important;
            font-size: 18px;
        }

        td {
            font-weight: bold;
            font-size: 23px;
            color: $azul !important;
        }
    }
}

.tabla-nodo.piscina-oxe2 tbody tr:last-child td:first-child,
.tabla-piscina tbody tr:last-child td:last-child {
    background-color: #fff !important;
}

.tabla-nodo.piscina-oxe2 tbody tr:last-child td:last-child,
.tabla-piscina tbody tr:last-child td:first-child  {
    background-color: #16539e2c !important;
    font-weight: bold;
}

.react-flow__edge-path, .react-flow__connection-path {
    stroke-width: 5;
}

.piscina-masa tbody, .pila-este-met tbody {
    td {
        font-weight: bold;
    }

    td:first-child {
        background-color: $gris !important;
        text-align: left;
        color: #fff;
    }
}

.forma-pila {
    width: 100%;
    height: 0px;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-bottom: 40px solid #008A60;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
    display: flex;
    
    h6 {
        width: 100%;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
    }
}


.forma-piscina {
    width: 100%;
    height: 0px;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-top: 80px solid #607d8b49;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.agua-piscina {
    top: 30px;
    position: absolute;
    width: 510px;
    height: 0px;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-top: 57px solid $azul;
}

.nodo {
    .tabla-nodo {
        margin-bottom: 10px !important;
    }

    .tabla-nodo:last-child {
        margin-bottom: 0 !important;
    }

    &.met, &.nodo-oxe {
        width: 450px !important;
    }

    &.sx {
        border: 1px solid $azul;
    }
}


.cont-piscina {
    border-radius: 7px;
    background-color: #16539e21;
    padding: 10px;
}

.cont-piscina .forma-piscina:last-child {
    margin-bottom: 0 !important;
}

.forma-piscina.piscinas {
    width: 100% !important;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-top: 70px solid #fff;

    .agua-piscina {
        width: 365px;
        border-top: 40px solid $azul;
    }

    .agua-piscina.pisc1 {
        top: 45px;
    }

    .agua-piscina.pisc2 {
        top: 125px;
    }
}

.titulo-nodo {
    margin: 0;
    text-align: center;
    font-weight: bold;
    color: $azul;
    font-size: 30px;
    margin-bottom: 10px;
}
