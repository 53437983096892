@import '../../../../app.scss';


.cont-reportabilidad {
    overflow-y: auto !important;
    overflow-x: hidden;
    max-height: 90vh;
    display: grid;
    grid-template-columns: 13% 87%;
    padding-right: 10px;
    column-gap: 10px;
    padding-right: 15px;
}

.tarjeta-reporte {
    height: auto !important;
    width: 100%;
    margin-bottom: 10px;
}

.nombre-tarjeta {
    text-align: LEFT;
    color: $azul !important;
    font-weight: bold;
    font-size: 16px;
}

.nombre-tarjeta i {
    bottom: 0 !important;
    font-size: 16px !important;
}

.btns-resumen-reportes {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;

    button {
        width: 100%;
        height: 30px;
        line-height: 0px !important;
        font-size: 15px;
        font-weight: bold;
        box-shadow: none;
        background-color: #a9bfcf !important;
        color: $azul;
        border-radius: 5px;

        &:hover {
            box-shadow: none;
            background-color: #96b8d1 !important;
        }

        &.activo {
            color: #fff;
            background-color: $azul !important;
        }
        
    }
}

.cont-cr, .cont-rlix {
    position: relative;
    display: none;

    &.activo {
        display: block;
    }

    > svg {
        width: 100%;
    }
}

.cont-resumen {
    max-height: 300px;
}

.tarjeta-reporte h6 {
    font-weight: bold;
    color: $gris-oscuro;
    font-size: 15px;
}

.chartWrapper {
    overflow-x: auto !important;
    overflow-y: hidden;
}

.chartWrapper > canvas {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events:none;
}

// .chartAreaWrapper {
//     width: 320%;
//     overflow-y: hidden;
//     overflow-x: hidden;
// }

.cont-btns-variables {
    margin-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;
}

.btn-variable {
    box-shadow: none !important;
    border-radius: 5px;
    font-weight: bold;
    font-size: 13px;
    height: 30px;
    line-height: 20px;
    background-color: #e2846741  !important;
    color: #724233 ;
    transition: 0.1s !important;
}

.btn-variable:hover {
    background-color: #e2846767  !important;
}

.btn-variable.activo {
    background-color: #e28467      !important;
    color: #fff ;
}

.graf-cr {
    display: none;
}

.graf-cr.activo {
    display: block !important;
}

.cont-leyendas-graf {
    width: 100%;
    display: flex;
    text-align: center;
    margin-top: 10px;
    justify-content: center;

    > div {
        display: flex;
        margin-right: 30px;
        font-size: 13px !important;
    }
}

.cubo {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
}

.cubo.real {
    background-color: #16549e;
}

.cubo.esperado {
    background-color: #ffc33c;
}

.cubo.obj {
    background-color: #a3d39c;
}

.tabla-estados-inicio {
    width: auto;
    border-collapse: separate !important;
    border: 1px solid #a1a1a13a;

    tbody tr td:first-child {
        width: 100% !important;
    }

    tbody tr td:last-child {
        width: 60px !important;
        min-width: 60px !important;
        max-width: 60px !important;
    }

    td, th {
        font-size: 15px !important;
    }

    td {
        padding: 5px !important;
        border-radius: 0 !important;
        border: 1px solid #fff;
        color: $gris-oscuro !important;
        font-weight: bold;
        transition: 0.1s;
    }

    tbody tr td:nth-child(2), tbody tr td:last-child {
        text-align: center;
        font-weight: normal !important;
    }

    tbody tr {
        background-color: rgba(242,242,242,0.5);
    }

    tbody tr.impar {
        background-color: #f2f2f2 !important;
    }

    tr:hover {
        td {
            background-color: #01589b25 !important;
        }
    }
}