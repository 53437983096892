@import '../../../../app.scss';

.cont-titulo-aq {
    input {
        margin-top: 10px !important;
        height:  auto !important;
        width: 200px !important;
        padding: 7px !important;
        border: 1px solid #e0e0e0 !important;
        border-radius: 7px !important;
        color: $gris-oscuro !important;
        transition: 0.1s !important;
        
        &:focus {
            border-color: $azul !important;
        }
    }
}

.celda-valor {
    text-align: center;
}

.cont-tabla-aq {
    overflow-y: auto;
    max-height: 68vh;
}

.tabla-analisis thead {
    tr {
        position: sticky;
    }

    tr:first-child {
        top: 0;
    }

    tr:last-child {
        top: 40px;
    }
}

.tabla-analisis td, .tabla-analisis th {
    border-radius: 0 !important;
    padding: 7px !important;
    border: 2px solid #ffff !important;
}

.tabla-analisis thead tr {
    th {
        text-align: center;
        color: #fff;
        font-size: 16px;
    }

    &:first-child th {
        background-color: $gris;
    }


    &:last-child th {
        background-color: $azul;
    }
}

.tabla-analisis tbody {
    tr:hover {
        td.cel-hora {
            font-weight: bold;
            background-color: #01589b7c !important;
            text-align: center;
        }
    
        td.cel-id {
            font-weight: bold;
            background-color: #01589b5b !important;
        }

        td {
            background-color: #01589b2f !important;
        }

        td.oscurecer {
            background-color: #01589b4f !important;
        }
    }

    td {
        color: $gris-oscuro;
        font-size: 15px;
    }

    // tr:nth-child(1), tr:nth-child(2),  tr:nth-child(3),  tr:nth-child(4), tr:nth-child(5),  tr:nth-child(6),  tr:nth-child(7),  tr:nth-child(8),
    // tr:nth-child(10), tr:nth-child(11), tr:nth-child(12),  tr:nth-child(13),   tr:nth-child(14),  tr:nth-child(15),  tr:nth-child(16),
    // tr:nth-child(36), tr:nth-child(37), tr:nth-child(38), tr:nth-child(39), tr:nth-child(40), tr:nth-child(41), tr:nth-child(42), tr:nth-child(43),
    // tr:nth-child(45), tr:nth-child(46), tr:nth-child(47),  tr:nth-child(48),  tr:nth-child(49),
    // tr:nth-child(57), tr:nth-child(58) {

    tr {
        td.cel-hora {
            font-weight: bold;
            background-color: #01589b52;
            text-align: center;
        }
    
        td.cel-id {
            font-weight: bold;
            background-color: #01589b2d;
        }
    
        td {
            background-color: #f2f2f2;
        }

        td.oscurecer {
            background-color: rgb(221, 221, 221);
        }
    }  
    
    // tr:nth-child(9), 
    // tr:nth-child(17), tr:nth-child(18),  tr:nth-child(19), tr:nth-child(20),  tr:nth-child(21),  tr:nth-child(22), 
    // tr:nth-child(23),  tr:nth-child(24), tr:nth-child(25), tr:nth-child(26), tr:nth-child(27), tr:nth-child(28), 
    // tr:nth-child(29),  tr:nth-child(30), tr:nth-child(31), tr:nth-child(32), tr:nth-child(33), tr:nth-child(34), tr:nth-child(35),
    // tr:nth-child(44),

    // tr:nth-child(50),  tr:nth-child(51), tr:nth-child(52),  tr:nth-child(53), tr:nth-child(54), tr:nth-child(55), tr:nth-child(56) {
    //     td.cel-hora {
    //         font-weight: bold;
    //         background-color: #01589b6c;
    //         text-align: center;
    //     }
    
    //     td.cel-id {
    //         font-weight: bold;
    //         background-color: #01589b41;
    //     }
    
    //     td {
    //         background-color: #e6e6e6;
    //     }

    //     td.oscurecer {
    //         background-color:  rgb(207, 207, 207);
    //     }
    // }  

    
}