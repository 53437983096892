@import '../../../../app.scss';

/*
    #007a91
    #006699
    #004e75

*/
.contenedor-reporte {
    max-height: 78vh;
    overflow-y: auto;
    padding-right: 5px;
}

.tabla-preliminar {
    margin-bottom: 15px;
    
    th, td {
        padding: 5px;
    }

    th {
        text-transform: uppercase;
        text-align: center;
    }

    td {
        color: $gris-oscuro;
    }

    td.centrado {
        text-align: center;
    }
    
    tr, td {
        transition: 0.2s;
    }
}

.dividido {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
}

.tabla-preliminar.aporte, .tabla-preliminar.puentes {
    th {
        border-radius: 0 !important;
    }

    > thead tr:first-child th {
        background-color: #007991dc !important;
        color: #fff;
        font-size: 18px;
    }

    > thead tr:last-child {
        font-size: 16px;
        background-color: #007991a9;
        color: #fff;
    }

    td {
        font-size: 15px;
        font-weight: bold;
        background-color: #daeef367;
        color: $gris-oscuro;
    }
}

.tabla-preliminar.hidro {
    th {
        border-radius: 0;
        color: #fff;
    }

    td {
        border-radius: 0 !important;
    }

    > thead tr:first-child th {
        background-color: #007991e0;
    }

    > thead tr:first-child th:nth-child(2) {
        background-color: #007991f6;
    }

    > thead tr:first-child th:nth-child(3) {
        background-color: #006fa7;
    }

    > thead tr:first-child th:nth-child(4) {
        background-color: #016090;
    }

    > thead tr:nth-child(2) th {
        background-color: #007991f6 !important;
    }

    > thead tr:nth-child(2) th:nth-child(2) {
        background-color: #006fa7 !important;
    }

    > thead tr:nth-child(2) th:nth-child(3) {
        background-color: #016090 !important;
    }


    > thead tr:nth-child(3) th {
        background-color: #007991e0 !important;
    }

    > thead tr:nth-child(3) th:nth-child(3), > thead tr:nth-child(3) th:nth-child(4), 
    > thead tr:nth-child(3) th:nth-child(5), > thead tr:nth-child(3) th:nth-child(6) {
        background-color: #00799196 !important; 
        color: #00596b;
    }

    > thead tr:nth-child(3) th:nth-child(7), > thead tr:nth-child(3) th:nth-child(8),
    > thead tr:nth-child(3) th:nth-child(9), > thead tr:nth-child(3) th:nth-child(10) {
        background-color: #006fa79c !important;
        color: #004a6e;
    }

    > thead tr:nth-child(3) th:nth-child(11), > thead tr:nth-child(3) th:nth-child(12),
    > thead tr:nth-child(3) th:nth-child(13), > thead tr:nth-child(3) th:nth-child(14) {
        background-color: #01609098 !important;
        color: #003853;
    }

    > tbody tr td:nth-child(3), > tbody tr td:nth-child(4), > tbody tr td:nth-child(5),
    > tbody tr td:nth-child(6) {
        background-color: #009cbb0c;

        &.colorear {
            background-color: #009cbb15;
        }
    }

    > tbody tr td:nth-child(7), > tbody tr td:nth-child(8), > tbody tr td:nth-child(9),
    > tbody tr td:nth-child(10) {
        background-color: #006fa70e;

        &.colorear {
            background-color: #006fa721;
        }
    }

    > tbody tr td:nth-child(11), > tbody tr td:nth-child(12), > tbody tr td:nth-child(13),
    > tbody tr td:nth-child(14) {
        background-color: #0160901a;

        &.colorear {
            background-color: #01609031;
        }
    }
    
    
    td {
        color: $gris-oscuro;
    }
    
    tr:hover {
        // background-color: #0079913a;
        td:nth-child(1), td:nth-child(2) {
            background-color: #00799125 !important;
        }

        td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6) {
            background-color: #0079913b !important;
        }

        td:nth-child(7), td:nth-child(8), td:nth-child(9), td:nth-child(10) {
            background-color: #006fa73b !important;
        }

        td:nth-child(11), td:nth-child(12), td:nth-child(13), td:nth-child(14) {
            background-color: rgba(0, 111, 167, 0.253) !important;
        }
        
    }

    td.subtitulo-preliminar {
        background-color: #daeef3;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
    }

    td.colorear {
        background-color: #daeef362;
    }

    td.cumplimiento {
        font-weight: bold;
    }

    td span {
        font-weight: bold;
        box-shadow: none !important;
        border-radius: 100px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        height: auto;
        line-height: 20px;
        color: #fff;
        font-size: 14px;

        &.amber {
            color: #816615;
        }
    }

    td button {
        font-weight: bold;
        box-shadow: none !important;
        border-radius: 100px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        height: auto;
        line-height: 20px;
        transition: transform 0.1s;

        &.amber {
            color: #816615;
        }


        &:hover {
            transform: scale(1.1);
        }
    }
}

.material-tooltip{
    background-color: #354c52;
    border-radius: 12px !important;
    font-size: 13px;
}

.tabla-preliminar.acido {
    td {
        border-radius: 0;
        transition: 0.2s;
    }

    > tbody tr:first-child td {
        background-color: #007991dc !important;   
        font-weight: bold;
        text-align: center;
        color: #fff;
    }

    > tbody tr:nth-child(2) td {
        text-align: center;
        font-weight: bold;
        background-color: #007991dc !important;
        color: #fff;
    }

    tbody tr td:first-child {
        font-weight: bold;
        background-color: #daeef3;
    }

    td.colorear {
        background-color: #daeef362;
    }

    tr {
        transition: 0.2s;
    }
    
    tr:hover {
        background-color: #0079913a;

        td:first-child {
            background-color: #00799121;
        }
    }
}

.tabla-preliminar.despacho {
    th, td {
        border-radius: 0;
    }

    th {
        background-color: rgba(0, 121, 145, 0.862745098);
        color: #fff;
    }

    tbody tr td:first-child {
        background-color: #daeef3;
        font-weight: bold;
        text-align: left !important;
        width: 300px;
    }

    td {
        text-align: center;
    }

    tr:hover {
        background-color: #0079913a !important;

        td:first-child {
            background-color: #00799121 !important;
        }
    }
}

.modal-preliminar {
    h4 {
        color: $azul;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: bold;
    }
}