@import '../../../../app.scss';

/**
 * timeline.css
 * @version 1.0.0
 * @summary 10-08-2021
 * @author Mads Stoumann
 * @description Stylesheet for timeline.
*/

.modal-historial-modulo {
    width: 50%;
    max-width: 50%;
}

.tmln {
	--bdc: #37474f86; // Línea de tiempo
	--bdc-a: #c62828; 
	--bdc-h: hsl(200, 30%, 90%);
	--bgc: hsl(200, 40%, 80%);
	--bdrs: 0.25rem;
	--bdw: 2px;
	--bullet-bdrs: 50%;
	--bullet-sz: 2.5em;
	--bullet-sz-a: 6px;
	--c: hsl(200, 40%, 1%);
	--box-item-bdrs: 0.25rem;
	--box-item-bgc: rgba(255, 255, 255, .15);
	--box-item-dark-bgc: hsl(200, 40%, 40%);
	--box-item-dark-c: hsl(200, 40%, 95%);
	--box-item-lght-bgc: hsl(200, 30%, 90%);
	--gap: 1rem;
	--scroll-bgc: hsl(200, 40%, 85%);
	--scroll-tmb-bgc: hsl(200, 40%, 70%);
	--scroll-w: 10px;
	--trsdu: .3s;
	--trstf: ease-out;

	/* Modifier-specific properties */
	--sl-mbe: 2em;
	--bi-miw: 280px;

	background-color: #D0D8E1;
	border-radius: 10px;
	color: #37474f;
    padding: 10px;
	width: var(--w, 100%);
	-webkit-tap-highlight-color: transparent;
}

// .tmln:not(.tmln--hr) {
// 	padding-inline-start: var(--gap);
// }

.tmln__item {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px 0px !important;
	color: inherit;
	display: block;
	// margin-block-end: var(--mbe, var(--gap));
	// padding-inline-start: calc(var(--gap) + var(--bullet-sz));
	position: relative;
	text-decoration: none;
}

/* Circle */
.tmln__item.Vacio::after {
    background-color: #e6e7f3 !important;
}

.tmln__item.Inicio_Apilamiento::after, .tmln__item.Fin_Apilamiento::after {
    background-color: #778d5c !important;
}

.tmln__item.Ruteo::after {
    background-color: #b6703b !important;
}

.tmln__item.Armado_Parrilla::after {
    background-color: #C5A463 !important;
}

.tmln__item.Humectacion::after, .tmln__item.Humectación::after {
    background-color: #3e5e7c !important;
}

.tmln__item.Riego::after {
    background-color: #427975 !important;
}

.tmln__item.Detenido_por_Fuerza_Mayor::after {
    background-color: #925d5d !important;
}

.tmln__item.Drenaje::after {
    background-color: #706072 !important;
}

.tmln__item.Desarme_de_Parrilla::after, .tmln__item.Desarme_de_Parilla::after {
    background-color: #434E5F !important;
}

.tmln__item.Inicio_Derripado::after, .tmln__item.Fin_Derripado::after {
    background-color: #484D52 !important;
}

.tmln__item::after {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px 0px !important;
	border: 3px solid #fff !important;
	border-radius: 30px;
	box-sizing: border-box;
	content: "";
	block-size: var(--bullet-sz);
	inline-size: var(--bullet-sz);
	inset-block-start: 0;
	inset-inline-start: 0;
	position: absolute;
	transition: all var(--trsdu) var(--trstf);
}

// Línea de tiempo
.tmln__item::before {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px 0px !important;
	background-color: #fff;
	content: "";
	block-size: calc(100% + var(--mbe, var(--gap)) - var(--bullet-sz));
	inline-size: var(--bdw);
	inset-block-start: var(--bullet-sz);
	inset-inline-start: calc((var(--bullet-sz) - var(--bdw)) / 2);
	position: absolute;
}

.tmln__list {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding-block: 0 var(--gap);
	padding-inline: 0;
}

.tmln--hr .tmln__list {
	flex-direction: row;
	flex-wrap: nowrap;
	overflow-x: auto;
	padding-block-start: var(--bullet-sz-a); /* Add room for box-shadow transition in horizontal mode */
	padding-inline-start: var(--gap);
	scrollbar-width: none;
	scroll-snap-type: x mandatory;
}

.tmln--hr .tmln__item {
	// align-self: flex-start;
	margin-block-end: 0;
	min-width: var(--miw, 6rem);
	padding-block-start: calc(var(--bullet-sz) + var(--gap));
	padding-inline-end: var(--gap);
	padding-inline-start: 0;
	scroll-snap-align: start;
	scroll-margin-inline-start: var(--gap);
	scroll-margin-left: var(--gap); /* Safari */
}

.tmln--hr .tmln__item::before {
	block-size: var(--bdw);
	inline-size: calc(100% - var(--bullet-sz));
	inset-block-start: calc((var(--bullet-sz) - var(--bdw)) / 2);
	inset-inline-start: calc(0% + var(--bullet-sz));
}

/* Item Box */
.tmln--box .tmln__item {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px 0px !important;
	background-color: #fff;
	border-radius: 10px;
	margin-block-end: 0;
	margin-inline-start: calc(var(--bullet-sz) + var(--gap));
	padding: var(--gap);
	transition: box-shadow var(--trsdu) var(--trstf);
}

.tmln--box .tmln__list {
	gap: var(--gap);
	padding-inline-end: var(--gap);
}

/* Item Box AND Horizontal */
.tmln--box.tmln--hr .tmln__item {
	--miw: var(--bi-miw);
	margin-block-end: 0;
	margin-block-start: calc(var(--bullet-sz) + var(--gap));
	margin-inline-start: 0;
}

.tmln--box.tmln--hr .tmln__item::after {
	inset-block-start: calc(0px - var(--bullet-sz) - var(--gap));
}
.tmln--box.tmln--hr .tmln__item::before {
	inset-block-start: calc(0px - var(--gap) - ((var(--bullet-sz) + var(--bdw)) / 2));
	inline-size: calc(100% - var(--bullet-sz) + var(--gap));
}

/* Single Line, center text to bullet */
.tmln--sl .tmln__item {
	--mbe: var(--sl-mbe, var(--gap));
	line-height: var(--bullet-sz);
}

/* Show scrollbars on devices without touch  */
@media (pointer: fine) {
	.tmln--hr {
		/* Add space between scrollbar and bottom of container */
		padding-block-end: var(--gap);
	}
	.tmln--hr .tmln__list {
		scrollbar-width: var(--scroll-w);
	}
	.tmln--hr .tmln__list {
		scrollbar-color: var(--scroll-tmb-bgc) var(--scroll-bgc);
		scrollbar-width: thin;
	}
	.tmln--hr .tmln__list::-webkit-scrollbar {
		background: #ffffff5b;
        border-radius: 30px;
		height: 10px;
		width: 10px;
	}
	.tmln--hr .tmln__list::-webkit-scrollbar-button {
        display: none;
	}
	.tmln--hr .tmln__list::-webkit-scrollbar-thumb {
		/* Hide scrollbar-button-area, so scrollbar appears smaller than container */
		background-color: #607d8b;
        border-radius: 30px;
	}
}


.estado-lntiempo {
    border-radius: 8px;
    // font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
    letter-spacing: 0.5px;

    &.Vacio {
        background-color: #e6e7f3 !important;
        color: #37474f;

        &:hover {
            background-color: #f4f5fc;
        }
    }

    &.Inicio_Apilamiento, &.Fin_Apilamiento {
        background-color: #778d5c !important;
        color: #fff;
        &:hover {
            background-color: #96b374;
        }
    }

    &.Ruteo {
        background-color: #b6703b;
        color: #fff;
        &:hover {
            background-color: rgb(223, 149, 93);
        }
    }

    &.Armado_Parrilla {
        background-color: #C5A463;
        color: #fff;
        &:hover {
            background-color: rgb(236, 203, 136);
        }
    }

    &.Humectacion, &.Humectación {
        background-color: #3e5e7c;
        color: rgba(255,255,255);
        &:hover {
            background-color: #4b7296;
        }
    }

    &.Riego {
        background-color: #427975;
        color: rgba(255,255,255);
        &:hover {
            background-color: #559994;
        }
    }

    &.Detenido_por_Fuerza_Mayor {
        background-color: #925d5d;
        color: rgba(255,255,255);
        &:hover {
            background-color: #ad7070;
        }
    }

    &.Drenaje {
        background-color: #706072;
        color: rgba(255,255,255);
        &:hover {
            background-color: #9f89a1;
        }
    }

    &.Desarme_de_Parrilla, &.Desarme_de_Parilla  {
        background-color: #434E5F;
        color: rgba(255,255,255);
        &:hover {
            background-color: #64748b;
        }
    }

    &.Inicio_Derripado, &.Fin_Derripado {
        background-color: #484D52;
        color: rgba(255,255,255);
        &:hover {
            background-color: #697077;
        }
    }
}

.datos-transicion {
    display: grid;
}

.modal-historial-modulo .usuario,
.modal-historial-modulo .fecha {
    text-align: left !important;
    margin: 3px;
    font-size: 16px;
}

.modal-historial-modulo .usuario {
    margin-top: 5px !important;
}

