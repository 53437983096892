.modal.captura {
    width: 98% ;
    background-color: #fff !important;
    overflow: hidden !important;
    .modal-content {
        padding: 10px;
        
    }
}

#captura-cosecha {
    padding: 15px;
    table {
        width: 100% !important;
        box-sizing: border-box !important;

        td, th {
            
            word-break: break-all !important;
            letter-spacing: 1.8px !important;
        }
    }
}
.capt-sx {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.ewsx1, .capt-lix {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    column-gap: 10px;
}

.carga-captura {
    position: absolute;
    width: 100%;
    background-color: #fff;
    width: 100%;
    z-index: 999 !important;
}