@import '../../../../app.scss';

.cont-btns-cosecha {
    width: 100%;
    display: flex;

    > button {
        width: 100%;
        margin-right: 5px;
        box-shadow: none !important;
        border-radius: 4px;
        font-size: 17px;
        color: $azul;
        font-weight: bold;
        background-color: #ECF4FF !important;
        transition: 0.2s !important;
    }

    > button:hover {
        background-color: #c7dcff !important;
    }

    > button:last-child {
        margin-right: 0 !important;
    }

    > button.activo {
        background-color: $azul !important;
        color: #fff;
    }
}

.btns-informe button {
    width: 100%;
    box-sizing: border-box !important;
    box-shadow: none !important;
    font-weight: bold;
    font-size: 17px;
    height: 35px;
    letter-spacing: 1px;
    border-radius: 7px 7px 0 0;
    border-right: 2px solid #dee4e7;
    border-top: 2px solid #dee4e7;
    border-left: 2px solid #dee4e7;
    color: $gris-oscuro;
    transition: 0.2s !important;
    
    background-color: #dee4e7 !important;

    &:hover {
        color: $azul;
    }

    &.activo {
        background-color: transparent !important;
        color: $azul;
        box-shadow: -0px 5px 0px -2px rgba(255,255,255,1) !important;
        -webkit-box-shadow: -0px 5px 0px -2px rgba(255,255,255,1);
        -moz-box-shadow: -0px 5px 0px -2px rgba(255,255,255,1);
    }
}

.btns-informe button:first-child {
    margin-right: 2px;
}

.btns-informe button i {
   font-size: 25px !important;
}

.btns-informe {
    width: 100%;
    display: flex;
    // margin-bottom: 10px;
}