@import '../../../app.scss';


nav {
    transition: 0.2s;
    height: 55px !important;
    align-items: center;
}

.nav-wrapper {
    padding-left: 70px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px !important;

    a {
        height: auto !important;
        max-height: 55px !important;
        display: flex;
        align-items: center;
    }

    > a img {
        width: 120px;
        transition: 0.2s;
    }

    > a:last-child {
        right: 0;
        // text-align: right !important;
        padding-right: 20px;
    }
}

.btn-flat  i {
    color: #fff;
    transition: 0.2s;

    &:hover {
        transform: scale(1.1);
    }
}

@media only screen and (max-width: 601px) {
    .nav-wrapper a img {
        width: 140px;
        margin-top: 4px;
    }
}

