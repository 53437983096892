@import '../../../../app.scss';

.btn-tab-historico {
    padding: 10px 25px 10px 25px;
    background-color: #fff !important;
    text-transform: none !important;
    font-weight: bold !important;
    font-size: 15px !important;
    min-width: 150px;
    border-left: 1px solid #EBEBEB !important;
    border-top: 1px solid #EBEBEB !important;
    border-right: 1px solid #EBEBEB !important;
    border-bottom: 1px solid #EBEBEB !important;
    margin-bottom: -1px !important;
    color: #333333 !important;
    border-radius: 7px 7px 0px 0 !important;

    &.activo {
        color: $azul !important;
        border-bottom: 1px solid #FFFFFF !important;
    }
}

.contenido-tab {
    box-sizing: border-box !important;
    padding: 15px;
    width: 100%;
    background-color: #ffff;
    border-radius: 0 7px 7px 7px;
    border: 1px solid #EBEBEB;
}

.tab-historico {
    display: none;

    &.activo {
        display: block;
    }
}

.cont-historico {
    display: grid;
    grid-template-columns: 0.3fr 1.7fr !important;
    column-gap: 10px;
    box-sizing: border-box !important;
}

.historico-vacio {
    width: 100%;
    height: 200px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    font-weight: bold;
    border-radius: 7px;
    color: #00000069;
}


.loader-cont {
    border: 5px solid $azul;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader-cont.tabla {
    width: 50px;
    height: 50px;
}

.loader-cont.filtro {
    width: 40px;
    height: 40px;
    border: 5px solid $azul;
    border-bottom-color: #ffffff;
}

.cont-loader-cont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

@keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
} 