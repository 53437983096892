@import '../../../../app.scss';

.cont-btns-tabs-rom {
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    button {
        border: none !important;
        width: 100%;
        box-shadow: none;
        border-radius: 7px;
        font-size: 16px !important;
        font-weight: bold;
        background-color: #ECF4FF !important;
        color: $azul;
    }

    button:first-child {
        margin-right: 5px;
    }

    button.activo {
        background-color: $azul !important;
        color: #fff;
    }
}

.cont-tab-rom {
    display: none;
}

.cont-tab-rom.activo {
    display: block;
}

.cont-btn-subtabs-rom{
    width: 100%;
    display: flex;

    > button {
        margin-right: 1px;
        width: 100%;
        box-sizing: border-box !important;
        box-shadow: none !important;
        font-weight: bold;
        font-size: 17px;
        height: 35px;
        letter-spacing: 1px;
        border-radius: 10px 10px 0 0;
        border-right: 2px solid #dee4e7;
        border-top: 2px solid #dee4e7;
        border-left: 2px solid #dee4e7;
        color: $gris-oscuro;
        transition: 0.2s !important;
        background-color: #dee4e7 !important;
    }

    > button:last-child {
        margin-right: 0 !important;
    }

    button:hover {
        color: $azul;
    }

    button.activo {
        background-color: transparent !important;
        color: $azul;
        box-shadow: -0px 5px 0px -2px rgba(255,255,255,1) !important;
        -webkit-box-shadow: -0px 5px 0px -2px rgba(255,255,255,1);
        -moz-box-shadow: -0px 5px 0px -2px rgba(255,255,255,1);
    }
    
}

.tab-rom {
    display: none;
    border-left: 2px solid #dee4e7 !important;
    border-right: 2px solid #dee4e7 !important;
    border-bottom: 2px solid #dee4e7 !important;
    padding: 15px ;

    border-radius: 0 0 12px 12px ;

    &.activo {
        display: block;
    }
}

.contenedor.rom {
    max-height: 95% !important;
    margin-bottom: 10px !important;
}